import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../pages/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/xtjw',
    component: () => import('../pages/Xtjw.vue')
  },
  {
    path: '/zhongyi',
    component: () => import('../pages/Zhongyi.vue')
  },
  {
    // not in used now
    path: '/page',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../pages/Menu.vue'),
    children: [

    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
