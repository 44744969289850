
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      year: (new Date()).getFullYear()
    }
  },
});
